<template>
  <div class="flex-col left-view">
    <div class="menus flex-col">
      <span class="menus-title">会员中心</span>
      <div class="menu-item flex-row align-center" @click="goMemberCenter">
        <div class="border-left" v-if="currentPathName == 'Gold'"></div>
        <span>我的账号</span>
      </div>
      <div class="menu-item flex-row align-center" @click="goDurationTable">
        <div
          class="border-left"
          v-if="currentPathName == 'DurationTable'"
        ></div>
        <span>时长明细</span>
      </div>
    </div>
    <div class="menus flex-col">
      <span class="menus-title">内容中心</span>
      <div class="menu-item flex-row align-center" @click="goMyOrder">
        <div class="border-left" v-if="currentPathName == 'MyOrderIndex'"></div>
        <span>我的订单</span>
      </div>
      <div class="menu-item flex-row align-center" @click="goMyVideo">
        <div class="border-left" v-if="currentPathName == 'MyVideo'"></div>
        <span>我的视频</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    }
  },
  created() {},
  methods: {
    goMemberCenter() {
      if (this.currentPathName == "Gold") {
        return;
      }
      this.$router.push({ name: "Gold" });
    },
    goPayDuration() {
      if (this.currentPathName == "PayDuration") {
        return;
      }
      this.$router.push({ name: "PayDuration" });
    },
    goDurationTable() {
      if (this.currentPathName == "DurationTable") {
        return;
      }
      this.$router.push({ name: "DurationTable" });
    },
    goMyOrder() {
      if (this.currentPathName == "MyOrderIndex") {
        return;
      }
      this.$router.push({ name: "MyOrderIndex" });
    },
    goMyVideo() {
      if (this.currentPathName == "MyVideo") {
        return;
      }
      this.$router.push({ name: "MyVideo" });
    },
    goMyCanva() {
      if (this.currentPathName == "MyCanva") {
        return;
      }
      this.$router.push({ name: "MyCanva" });
    },
  },
};
</script>

<style lang="scss">
.left-view {
  .menus {
    margin-top: 2rem;
    position: relative;
    &:nth-child(2) {
      margin-top: 15rem;
    }
    .menus-title {
      font-size: 16rem;
      color: #b1b2b2;
      line-height: 22rem;
      margin-bottom: 2rem;
    }
    .menu-item {
      width: 184rem;
      height: 45rem;
      background: #fff;
      border-radius: 6rem;
      margin-top: 8rem;
      cursor: pointer;
      position: relative;
      // transition: all 0.2s;
      &:hover {
        background: #f8f8f8;
      }
      .border-left {
        position: absolute;
        left: 0;
        top: 14rem;
        width: 3rem;
        height: 17rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }
      span {
        font-size: 18rem;
        color: #252927;
        line-height: 25rem;
        margin-left: 17rem;
      }
    }
  }
}
</style>
