<template>
  <div>
    <div class="pay-titlt flex-row align-center">
      <div class="border-left"></div>
      <span
        class="title-txt"
        :style="currentPathName == 'AddLaunchInfo' ? 'font-weight: 300;' : ''"
        >支付方式：</span
      >
      <div class="service flex-row align-center">
        更多支付方式（如企业网银转账），请
        <txtServicePopover />
      </div>
    </div>
    <el-radio-group class="payType flex-row" v-model="payType">
      <el-radio :label="1" border
        ><img src="@/assets/icon/weixin.png" alt="" /><span
          >微信支付</span
        ></el-radio
      >
      <el-radio :label="4" border style="width: 150rem"
        ><img src="@/assets/icon/zhifubao.png" alt="" /><span
          >支付宝</span
        ></el-radio
      >
    </el-radio-group>
    <div
      v-if="!submitStatus && !payDisabled"
      class="pay-btn"
      :style="
        currentPathName == 'Gold'
          ? 'margin-top: 55rem'
          : currentPathName == 'AddLaunchInfo'
          ? 'margin-top: 47rem'
          : ''
      "
      id="primary-btn"
      @click="pay"
    >
      立即支付
    </div>
    <div
      v-else-if="!submitStatus && payDisabled"
      class="payDisabled-btn"
      :style="
        currentPathName == 'Gold'
          ? 'margin-top: 55rem'
          : currentPathName == 'AddLaunchInfo'
          ? 'margin-top: 47rem'
          : ''
      "
    >
      立即支付
    </div>
    <div
      v-else
      class="loading-btn flex-row justify-center align-center"
      :style="
        currentPathName == 'Gold'
          ? 'margin-top: 55rem'
          : currentPathName == 'AddLaunchInfo'
          ? 'margin-top: 47rem'
          : ''
      "
      id="primary-btn"
    >
      <img src="@/assets/img/loading.gif" width="60" height="60" />
    </div>
  </div>
</template>

<script>
import txtServicePopover from "./txtServicePopover.vue";

/* eslint-disable vue/require-valid-default-prop */
export default {
  components: {
    txtServicePopover,
  },
  props: {
    submitStatus: {
      type: Boolean,
      default: false,
    },
    payDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      payType: 1,
    };
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    }
  },
  methods: {
    pay() {
      this.$emit("clickPay", this.payType);
    },
    changePayType(defPayType) {
      this.payType = defPayType;
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-titlt {
  height: 18rem;
  line-height: 18rem;
  margin-bottom: 28rem;
  .border-left {
    width: 3rem;
    height: 18rem;
    background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
  }
  .title-txt {
    font-size: 18rem;
    color: #252927;
    line-height: 25rem;
    margin-left: 14rem;
  }
  .service {
    height: 33rem;
    background: rgba(152, 152, 152, 0.08);
    border-radius: 12rem;
    font-size: 16rem;
    color: #7c7c7c;
    padding: 0 8rem;
  }
}
.pay-btn,
.loading-btn {
  width: 166rem;
  height: 48rem;
  text-align: center;
  line-height: 48rem;
  color: #fff;
  border-radius: 31rem;
  margin-top: 32rem;
  margin-left: 17rem;
}
.payDisabled-btn {
  width: 166rem;
  height: 48rem;
  text-align: center;
  line-height: 48rem;
  background: #ececec;
  color: #9c9d9d;
  border-radius: 31rem;
  margin-top: 32rem;
  cursor: not-allowed;
  margin-left: 17rem;
}
::v-deep .payType {
  padding-left: 17rem;
  .el-radio__input {
    display: none;
  }
  .icon {
    font-size: 20rem;
    margin-right: 5rem;
  }
  img {
    width: 24rem;
    margin-right: 10rem;
  }
  span {
    font-size: 16rem;
    color: #252927;
  }
}
::v-deep .el-radio.is-bordered {
  width: 166rem !important;
  height: 48rem !important;
  text-align: center;
  line-height: 48rem !important;
  border-radius: 11rem;
  border: 1rem solid #e0e0e0;
  padding: 0;
  transition: all 0.3s;
  &:hover {
    border: 1rem solid #32c3f6;
  }
}
::v-deep .el-radio:nth-child(1) {
  .icon {
    color: #09bb07;
  }
  .el-radio__input.is-checked + .el-radio__label {
    span {
      color: #606266;
    }
  }
}
::v-deep .el-radio {
  margin-right: 14rem;
}
::v-deep .el-radio__label {
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
// ::v-deep .el-radio:nth-child(2),
// ::v-deep .el-radio:nth-child(3) {
//   .icon {
//     color: #02a9f1;
//   }
//   .el-radio__input.is-checked + .el-radio__label {
//     span {
//       color: #606266;
//     }
//   }
// }
::v-deep .el-radio.is-checked {
  background: linear-gradient(
    225deg,
    rgba(79, 172, 254, 0.1) 0%,
    rgba(40, 218, 227, 0.1) 100%
  );
  border: 1rem solid #32c3f6;
}
</style>
