<template>
  <div class="page flex-row justify-between">
    <menuTabs />
    <div class="gold flex-col">
      <div class="screen-view">
        <div class="screenTitle-view flex-row justify-between">
          <div class="screenTitle flex-row align-center">
            <div class="border-left"></div>
            <span class="screenTitle-txt">请选择屏幕：</span>
            <div class="screenTitle-desc">
              <span>本次充值的时长仅支持在选中的屏幕上使用</span>
            </div>
          </div>
        </div>
        <div class="screenList flex-row align-center">
          <div
            v-for="(item, index) in screenList"
            :key="index"
            :class="{ screenItem: true, active: currentScreenIndex == index }"
            @click="clickScreen(item, index)"
          >
            <span>{{ item.remark }}</span>
          </div>
        </div>
      </div>
      <div class="payDuration-view flex-col">
        <div class="payTitle-view flex-row align-center">
          <div class="payTitle flex-row align-center">
            <div class="border-left"></div>
            <span class="payTitle-txt">充值时长：</span>
            <div
              class="payTitle-desc"
              style="margin-right: 8rem"
              v-if="!userInfo.businessInfo.isBuyVip"
            >
              <span>时长不清零，可永久使用</span>
            </div>
          </div>
          <el-popover
            class="popover"
            placement="top"
            trigger="hover"
            :visible-arrow="false"
            popper-class="payDurationPopover"
          >
            <div class="popover-content flex-col justify-between">
              <span style="font-size: 16rem; color: #252927"
                >"充值时长"可在下单的时候抵扣费用。</span
              >
              <span style="font-size: 16rem; color: #8b8b8b; margin-top: 10rem"
                >举例：若您充值时长500秒，当您下单投放广告1000秒时，系统会优先使用充值时长进行抵扣，您只需为抵扣后的500秒支付费用。</span
              >
            </div>
            <div slot="reference" class="reference flex-row align-center">
              <svg-icon class="service-svg" iconClass="overlay"></svg-icon>
            </div>
          </el-popover>
        </div>
        <div class="tab-view" v-if="userInfo.businessInfo.isBuyVip">
          <img
            v-if="buyType == 1"
            class="discount-bg"
            src="@/assets/img/discount-tab.png"
            alt=""
          />
          <img
            v-else
            class="common-bg"
            src="@/assets/img/common-tab.png"
            alt=""
          />
          <div class="discount-tab flex-col align-center" @click="buyType = 1">
            <span :style="buyType == 1 ? 'color:#2BB3E3' : 'color: #252927;'"
              >每月优惠</span
            >
            <span>一折优惠，每个月限购一次</span>
          </div>
          <div class="common-tab flex-col align-center" @click="buyType = 2">
            <span :style="buyType == 2 ? 'color:#2BB3E3' : 'color: #252927;'"
              >普通充值</span
            >
            <span>时间不清零，可永久使用</span>
          </div>
        </div>
        <div
          v-if="buyType == 1"
          :class="{
            'payOptions-view': true,
            isAllDisabled: isAllDisabled,
          }"
          @mouseenter="payOptionsViewEnter"
          @mouseleave="payOptionsViewLeave"
          @click="clickIsAll"
        >
          <div class="checked-cover" ref="payOptionsViewCover"></div>
          <img class="discount-icon" src="@/assets/img/overlay.png" alt="" />
          <div
            :class="{
              'check-all': true,
              'all-active': isAll,
              'check-all-disabled': isAllDisabled,
            }"
          >
            <img src="@/assets/img/big_active.png" alt="" />
            <span>全选</span>
          </div>
          <div
            v-for="(item, index) in payOptions"
            :key="index"
            :class="{
              'options-item': true,
              active: item.checked,
              disabled: item.disabled,
            }"
            @click.stop="payOptionsClick(item)"
            @mouseenter="optionsItemEnter"
            @mouseleave="optionsItemLeave"
          >
            <div class="checked-cover" v-if="!item.disabled"></div>
            <div
              :class="{
                'check-item': true,
                'check-item-active': item.checked,
              }"
            >
              <img src="@/assets/img/small_active.png" alt="" />
            </div>
            <div class="stopwatch flex-row align-center">
              <img src="@/assets/img/stopwatch.png" alt="" />
              <span>{{ item.secondNum }}秒</span>
            </div>
            <div class="discount-price">
              <span>￥</span><span>{{ item.secondNum / 10 }}</span>
            </div>
            <span class="original-price">￥{{ item.secondNum }}</span>
            <div class="save" v-if="!item.disabled">
              可省 ￥{{ item.secondNum * 0.9 }}
            </div>
            <div class="save-disabled" v-else>本月已购买</div>
          </div>
          <span class="payOptions-desc"
            >*黄金会员专属，每月每种充值卡限购一次，原价每秒 1 元，现每月最多可省 4500元，有效期为充值时刻+30天</span
          >
        </div>
        <div
          v-if="buyType == 2"
          :class="{
            'commonPayOptions-view': true,
          }"
        >
          <div
            v-for="(item, index) in commonPayOptions"
            :key="index"
            :class="{
              'options-item': true,
              active: item.checked,
            }"
            @click.stop="commonPayOptionsClick(item)"
          >
            <div class="checked-cover"></div>
            <div
              :class="{
                'check-item': true,
                'check-item-active': item.checked,
              }"
            >
              <img src="@/assets/img/small_active.png" alt="" />
            </div>
            <div class="stopwatch flex-row align-center" style="height: 82rem">
              <img src="@/assets/img/stopwatch.png" alt="" />
              <span>{{ item.secondNum }}秒</span>
            </div>
            <div class="save">￥{{ item.secondNum }}</div>
          </div>
        </div>
      </div>
      <div class="money-view flex-row align-center">
        <div class="border-left"></div>
        <span class="moneyTitle-txt">金额：</span>
        <div class="money-txt">
          <span>￥</span>
          <span v-if="buyType == 1">{{ payMoneyNum }}</span>
          <span v-else>{{ commonPayMoneyNum }}</span>
        </div>
        <span class="second-txt" v-if="buyType == 1"
          >（{{ getSecondNum }}秒）</span
        >
        <span class="second-txt" v-else>（{{ commonGetSecondNum }}秒）</span>
      </div>
      <pay-type
        v-if="buyType == 1"
        @clickPay="pay"
        :submitStatus="submitStatus"
        :payDisabled="payMoneyNum == 0"
      ></pay-type>
      <pay-type
        v-else
        @clickPay="pay"
        :submitStatus="submitStatus"
        :payDisabled="commonPayMoneyNum == 0"
      ></pay-type>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line import/no-cycle */
import {
  getBuyTimeInfo,
  createBuyTime,
  createCommonBuyTime,
  getUserInfo,
  getListDaping,
} from "@/api/request";
import payType from "@/components/payType.vue";
import menuTabs from "@/components/menuTabs.vue";

export default {
  components: {
    payType,
    menuTabs,
  },
  data() {
    return {
      isAllDisabled: true, // 是否禁用全选（本月是否还有套餐可以买）
      submitStatus: false,
      currentScreenIndex: 0,
      buyType: 1,
      screenList: [], // 屏幕列表
      userInfo: {
        businessInfo: {
          isBuyVip: false,
        },
      },
      payOptions: [
        {
          secondNum: 500, // 可购买时长
          originalPrice: 500, // 原价
          discountPrice: 50, // 折扣价
          disabled: false, // 是否禁用（本月已买）
          checked: false, // 是否选中
        },
        {
          secondNum: 1000,
          originalPrice: 1000,
          discountPrice: 100,
          disabled: false,
          checked: false,
        },
        {
          secondNum: 1500,
          originalPrice: 1500,
          discountPrice: 150,
          disabled: false,
          checked: false,
        },
        {
          secondNum: 2000,
          originalPrice: 2000,
          discountPrice: 200,
          disabled: false,
          checked: false,
        },
      ],
      commonPayOptions: [
        {
          secondNum: 500, // 可购买时长
          originalPrice: 500, // 原价
          checked: true, // 是否选中
        },
        {
          secondNum: 1000,
          originalPrice: 1000,
          checked: false,
        },
        {
          secondNum: 1500,
          originalPrice: 1500,
          checked: false,
        },
        {
          secondNum: 2000,
          originalPrice: 2000,
          checked: false,
        },
      ],
    };
  },
  computed: {
    payMoneyNum() {
      let num = 0;
      this.payOptions.forEach((item) => {
        if (!item.disabled && item.checked) {
          num += item.discountPrice;
        }
      });
      return num;
    },
    getSecondNum() {
      let num = 0;
      this.payOptions.forEach((item) => {
        if (!item.disabled && item.checked) {
          num += item.secondNum;
        }
      });
      return num;
    },
    commonPayMoneyNum() {
      let num = 0;
      this.commonPayOptions.forEach((item) => {
        if (item.checked) {
          num = item.originalPrice;
        }
      });
      return num;
    },
    commonGetSecondNum() {
      let num = 0;
      this.commonPayOptions.forEach((item) => {
        if (item.checked) {
          num = item.secondNum;
        }
      });
      return num;
    },
    isAll() {
      // 是否全选状态
      let isAllInit;
      if (this.isAllDisabled) {
        isAllInit = false;
      } else {
        isAllInit = true;
      }
      this.payOptions.forEach((item) => {
        // 如果有一个可选套餐未勾选
        if (!item.disabled && item.checked == false) {
          isAllInit = false;
        }
      });
      return isAllInit;
    },
  },
  created() {
    this.getListDaping();
    this.getUserInfo();
  },
  methods: {
    // 获取屏幕信息
    getListDaping() {
      getListDaping({}).then((res) => {
        this.$store.dispatch("screenListActions", res.list);
        // 过滤已下架（down为true）的屏幕
        const currentScreenList = [...res.list];
        if (currentScreenList.length) {
          this.screenList = currentScreenList.filter((v) => !v.down);
          // 如果有已选屏幕
          if (this.$route.query.name) {
            this.currentScreenIndex = this.screenList.findIndex(
              (v) => v.name == this.$route.query.name
            );
            // 如果找不到已选屏幕（假如已选屏幕已下架），则默认选中第一块屏幕
            if (this.currentScreenIndex == -1) {
              this.currentScreenIndex = 0;
            }
          }
        }
        this.getBuyTimeInfo();
      });
    },
    // 查询用户信息
    getUserInfo() {
      getUserInfo({}).then((res) => {
        this.userInfo = res.userInfo;
        this.$store.dispatch("setUserInfoActions", res.userInfo);
        // 普通用户只能普通充值
        if (!res.userInfo.businessInfo.isBuyVip) {
          this.buyType = 2;
        }
      });
    },
    // 查询免费时长
    getBuyTimeInfo() {
      const screenName = this.screenList[this.currentScreenIndex].name; // 屏幕名称
      getBuyTimeInfo({
        name: screenName,
      }).then((res) => {
        this.payOptions.forEach((item, index) => {
          item.checked = false; // 每次重置选中状态
          switch (index) {
            case 0:
              item.disabled = res.a;
              break;
            case 1:
              item.disabled = res.b;
              break;
            case 2:
              item.disabled = res.c;
              break;
            case 3:
              item.disabled = res.d;
              break;
            default:
              item.disabled = false;
              break;
          }
        });
        this.isAllDisabled = true; // 每次重置全选禁用状态
        // 本月如果有一个套餐可以买，全选就可以选
        if (!res.a || !res.b || !res.c || !res.d) {
          this.isAllDisabled = false;
        }
        // 默认选中最小的套餐
        if (!res.a) {
          this.payOptions[0].checked = true;
        } else if (!res.b) {
          this.payOptions[1].checked = true;
        } else if (!res.c) {
          this.payOptions[2].checked = true;
        } else if (!res.d) {
          this.payOptions[3].checked = true;
        }
      });
    },
    payOptionsClick(item) {
      if (item.disabled) return;
      item.checked = !item.checked;
    },
    commonPayOptionsClick(item) {
      this.commonPayOptions.forEach((OptionsItem) => {
        OptionsItem.checked = false;
      });
      item.checked = true;
    },
    clickIsAll() {
      if (this.isAllDisabled) return;
      if (this.isAll) {
        // 如果是全选状态，就变为全不选
        this.payOptions.forEach((item) => {
          if (!item.disabled) {
            item.checked = false;
          }
        });
      } else {
        this.payOptions.forEach((item) => {
          if (!item.disabled) {
            item.checked = true;
          }
        });
      }
    },
    // 支付订单
    pay(type) {
      this.submitStatus = true;
      const screenName = this.screenList[this.currentScreenIndex].name; // 屏幕名称
      const params = {
        name: screenName,
        pay_type: type,
        order_expire_minute: 10,
      };
      // 一折充值
      if (this.buyType == 1) {
        this.payOptions.forEach((item, index) => {
          if (!item.disabled && item.checked) {
            switch (index) {
              case 0:
                params.a = true;
                break;
              case 1:
                params.b = true;
                break;
              case 2:
                params.c = true;
                break;
              case 3:
                params.d = true;
                break;
              default:
                break;
            }
          }
        });
        // console.log(params);
        createBuyTime(params)
          .then((res) => {
            this.submitStatus = false;
            // 如果本月已购买
            if (res.hasBeenBuy) {
              this.$message({
                type: "error",
                center: true,
                message: "本月已购买",
              });
              this.getUserInfo();
              this.getBuyTimeInfo();
              return;
            }
            // 新版支付，直接跳转至支付状态页
            this.$router.push({
              name: "MemCenPayStatus",
              query: {
                buyTimeOrderId: res.buyTimeOrderId,
              },
            });
            // // 如果选择了微信支付，则直接跳转支付状态查询页
            // if (type == "1") {
            //   this.$router.push({
            //     name: "MemCenPayStatus",
            //     query: {
            //       buyTimeOrderId: res.buyTimeOrderId,
            //     },
            //   });
            //   return;
            // }
            // // 如果选择了支付宝，则直接跳转支付宝第三方支付页面
            // if (type == "2") {
            //   // 加个延时器，不然跳转会被浏览器拦截
            //   setTimeout(() => {
            //     window.open(res.url, "_self");
            //   }, 100);
            // }
          })
          .catch((code) => {
            this.submitStatus = false;
            if (code == "40005") {
              setTimeout(() => {
                // window.location.reload();
                this.getUserInfo();
              }, 1000);
            }
          });
      }
      // 普通充值
      if (this.buyType == 2) {
        params.buy_time = this.commonGetSecondNum;
        // console.log(params);
        createCommonBuyTime(params)
          .then((res) => {
            this.submitStatus = false;
            // 新版支付，直接跳转至支付状态页
            this.$router.push({
              name: "MemCenBcPayStatus",
              query: {
                commonBuyTimeOrderId: res.commonBuyTimeOrderId,
              },
            });
            // // 如果选择了微信支付，则直接跳转支付状态查询页
            // if (type == "1") {
            //   this.$router.push({
            //     name: "MemCenBcPayStatus",
            //     query: {
            //       commonBuyTimeOrderId: res.commonBuyTimeOrderId,
            //     },
            //   });
            //   return;
            // }
            // // 如果选择了支付宝，则直接跳转支付宝第三方支付页面
            // if (type == "2") {
            //   // 加个延时器，不然跳转会被浏览器拦截
            //   setTimeout(() => {
            //     window.open(res.url, "_self");
            //   }, 100);
            // }
          })
          .catch((code) => {
            console.log(code);
            this.submitStatus = false;
          });
      }
    },
    // 点击切换屏幕
    clickScreen(item, index) {
      // console.log(item, index);
      if (this.currentScreenIndex == index) return;
      this.currentScreenIndex = index;
      this.getBuyTimeInfo(); // 重新查询免费时长使用情况
    },
    optionsItemEnter() {
      this.$refs.payOptionsViewCover.style.opacity = 0;
    },
    optionsItemLeave() {
      this.$refs.payOptionsViewCover.style.opacity = 1;
    },
    payOptionsViewEnter() {
      this.$refs.payOptionsViewCover.style.opacity = 1;
    },
    payOptionsViewLeave() {
      this.$refs.payOptionsViewCover.style.opacity = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  background-color: #fff;
  min-height: calc(100vh - 233rem);
  padding: 60rem 320rem 0;
  .gold {
    width: 1060rem;
    margin: 0 0 100rem;
    .screen-view {
      .screenTitle-view {
        margin-bottom: 24rem;
        .screenTitle {
          .border-left {
            width: 3rem;
            height: 18rem;
            background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
          }
          .screenTitle-txt {
            font-size: 18rem;
            color: #252927;
            line-height: 25rem;
            margin-left: 14rem;
          }
          .screenTitle-desc {
            padding: 5rem 8rem 6rem;
            background: #f7f7f7;
            border-radius: 12rem;
            span {
              font-size: 16rem;
              line-height: 22rem;
              color: #7c7c7c;
            }
          }
        }
      }
      .screenList {
        padding-left: 17rem;
        .screenItem {
          border-radius: 11rem;
          border: 1px solid #e0e0e0;
          padding: 14rem 38rem;
          margin-right: 24rem;
          font-size: 18rem;
          color: #252927;
          line-height: 25rem;
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            border: 1px solid #32c3f6;
          }
        }
        .active {
          background: linear-gradient(
            225deg,
            rgba(79, 172, 254, 0.1) 0%,
            rgba(40, 218, 227, 0.1) 100%
          );
          border: 1px solid #32c3f6;
        }
      }
    }
    .payDuration-view {
      margin: 42rem 0 35rem 0;
      .payTitle-view {
        margin-bottom: 24rem;
        .payTitle {
          .border-left {
            width: 3rem;
            height: 18rem;
            background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
          }
          .payTitle-txt {
            font-size: 18rem;
            color: #252927;
            line-height: 25rem;
            margin-left: 14rem;
          }
          .payTitle-desc {
            padding: 5rem 8rem 6rem;
            background: #f7f7f7;
            border-radius: 12rem;
            span {
              font-size: 16rem;
              line-height: 22rem;
              &:nth-child(1) {
                color: #7c7c7c;
              }
              // &:nth-child(2) {
              //   color: #252927;
              // }
            }
          }
        }
        .reference {
          cursor: pointer;
          .service-svg {
            color: #696969;
            width: 26rem;
            height: 26rem;
          }

          &:hover {
            .service-svg {
              color: #32c3f6;
            }
          }
        }
      }
      .tab-view {
        position: relative;
        width: 100%;
        left: 2px;
        .discount-bg,
        .common-bg {
          width: 100%;
          height: 100%;
        }
        .discount-tab,
        .common-tab {
          width: 264rem;
          height: 100%;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          // border: 1px solid red;
          span {
            &:nth-child(1) {
              font-size: 18rem;
              font-weight: 500;
              line-height: 25rem;
              margin-top: 12rem;
            }
            &:nth-child(2) {
              font-size: 16rem;
              color: #a5a8a9;
              line-height: 22rem;
              margin-top: 2rem;
            }
          }
        }
        .common-tab {
          left: 300rem;
          // border: 1px solid blue;
        }
      }
      .payOptions-view,
      .commonPayOptions-view {
        width: 100%;
        height: 285rem;
        display: flex;
        align-items: center;
        position: relative;
        border: 2px solid #fff;
        border-radius: 12rem;
        background: url("../../assets/img/payOptions_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 0 7rem;
        cursor: pointer;
        transition: all 0.2s;
        bottom: 12rem;
        border-top: none !important;
        // &:hover {
        //   border: 2px solid #2ed3e7;
        // }
        .discount-icon {
          width: 135rem;
          position: absolute;
          top: -10rem;
          right: -8rem;
          z-index: 3;
        }
        .check-all {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 102rem;
          height: 40rem;
          background: #e4f9ff;
          border-radius: 9rem 0px 12rem 0px;
          padding: 0 16rem 0 24rem;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.2s;
          cursor: pointer;
          img {
            width: 16rem;
          }
          span {
            font-size: 18rem;
            color: #555858;
            line-height: 25rem;
          }
        }
        .all-active {
          background: #2ed3e7;
          span {
            color: #fff;
          }
        }
        .check-all-disabled {
          cursor: not-allowed;
          span {
            color: #c5c6c8;
          }
        }
        .options-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 200rem;
          height: 166rem;
          border-radius: 12rem;
          background-color: #fff;
          margin-left: 49rem;
          transition: all 0.2s;
          cursor: pointer;
          position: relative;
          z-index: 3;

          .checked-cover {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 12rem;
            border: 2px solid #2ed3e7;
            box-sizing: border-box;
            -moz-box-sizing: border-box; /* Firefox */
            -webkit-box-sizing: border-box; /* Safari */
            opacity: 0;
            z-index: 2;
            transition: all 0.2s;
          }
          &:hover {
            .checked-cover {
              opacity: 1;
            }
          }
          .check-item {
            width: 41rem;
            height: 22rem;
            background: #ecfaff;
            border-radius: 12rem 0px 12rem 0px;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.2s;
            img {
              width: 15rem;
            }
          }
          .check-item-active {
            background: #2ed3e7;
          }
          .stopwatch {
            margin-top: 21rem;
            img {
              width: 14rem;
            }
            span {
              font-size: 18rem;
              font-weight: 500;
              color: #252927;
              line-height: 25rem;
              margin-left: 4rem;
            }
          }
          .discount-price {
            margin-top: 10rem;
            span {
              &:nth-child(1) {
                font-size: 16rem;
                font-weight: 500;
                color: #2bb3e3;
                line-height: 22rem;
              }
              &:nth-child(2) {
                font-size: 24rem;
                font-weight: 500;
                color: #2bb3e3;
                line-height: 33rem;
                margin-left: 6rem;
              }
            }
          }
          .original-price {
            font-size: 14rem;
            color: rgba(37, 41, 39, 0.4);
            line-height: 20rem;
            margin-top: 6rem;
            text-decoration: line-through;
          }
          .save,
          .save-disabled {
            width: 200rem;
            height: 40rem;
            background: #daf5ff;
            border-radius: 0px 0px 12rem 12rem;
            text-align: center;
            line-height: 40rem;
            font-size: 14rem;
            color: #2bb3e3;
            margin-top: 11rem;
          }
          .save-disabled {
            background: #f0f1f2;
            color: #a5a8a9;
          }
        }
        .active {
          .checked-cover {
            opacity: 1;
          }
        }
        .disabled {
          cursor: not-allowed;
          background: #f6fdff;
        }
        .payOptions-desc {
          font-size: 16rem;
          color: #a5a8a9;
          line-height: 22rem;
          position: absolute;
          bottom: 18rem;
          left: 56rem;
        }
      }
      .payOptions-view {
        .checked-cover {
          width: 100%;
          height: 100%;
          position: absolute;
          top: -2px;
          left: 0;
          border-radius: 12rem;
          border: 2px solid #2ed3e7;
          // border-top: none;
          box-sizing: border-box;
          -moz-box-sizing: border-box; /* Firefox */
          -webkit-box-sizing: border-box; /* Safari */
          opacity: 0;
          z-index: 2;
          transition: all 0.2s;
        }
      }
      .commonPayOptions-view {
        cursor: auto;
        .options-item {
          .save {
            width: 200rem;
            height: 52rem;
            background: #daf5ff;
            border-radius: 0px 0px 12rem 12rem;
            text-align: center;
            line-height: 52rem;
            font-size: 22rem;
            color: #2bb3e3;
            margin-top: 11rem;
          }
        }
      }
      .isAllDisabled {
        background: linear-gradient(180deg, #f1f1f2 0%, #ffffff 100%);
      }
    }
    .money-view {
      margin-bottom: 42rem;
      .border-left {
        width: 3rem;
        height: 18rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }
      .moneyTitle-txt {
        font-size: 18rem;
        color: #252927;
        line-height: 25rem;
        margin: 0 8rem 0 14rem;
      }
      .money-txt {
        color: #252927;
        margin-right: 8rem;
        span {
          &:nth-child(1) {
            font-size: 20rem;
            line-height: 28rem;
          }
          &:nth-child(2) {
            font-size: 28rem;
            line-height: 40rem;
            margin-left: 4rem;
          }
        }
      }
      .second-txt {
        font-size: 18rem;
        color: #a4a8a9;
        line-height: 25rem;
      }
    }
  }
  .ordinary {
    width: 1060rem;
    margin-top: 109rem;
    .ordinary-title {
      font-size: 30rem;
      color: #252927;
      line-height: 42rem;
    }
    .ordinary-desc {
      margin: 25rem 0 35rem 0;
      img {
        width: 27rem;
      }
      span {
        font-size: 20rem;
        font-weight: 500;
        color: #32c3f6;
        line-height: 28rem;
        margin-left: 12rem;
      }
    }
    .popover {
      margin-bottom: 124rem;
      .service-txt {
        width: 210rem;
        height: 48rem;
        background: linear-gradient(225deg, #4facfe 0%, #09d5df 100%);
        border-radius: 27rem;
        text-align: center;
        line-height: 48rem;
        font-size: 16rem;
        font-weight: 500 !important;
        color: #ffffff;
      }
    }
  }
}
</style>
